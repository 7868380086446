import React from 'react';

interface MultilingualCountryCodeContext {
  multilingualCountryCode: string | undefined;
}

const MultilingualCountryCodeContainer = React.createContext<MultilingualCountryCodeContext>({
  multilingualCountryCode: undefined,
});

export const MultilingualCountryCodeProvider: React.FC<MultilingualCountryCodeContext> = ({
  children,
  multilingualCountryCode,
}) => {
  return (
    <MultilingualCountryCodeContainer.Provider value={{ multilingualCountryCode }}>
      {children}
    </MultilingualCountryCodeContainer.Provider>
  );
};

export const useMultilingualCountryCode = () => React.useContext(MultilingualCountryCodeContainer);
