import { useMemo, useState } from 'react';
import { PublicPlan } from '@wix/ambassador-pricing-plans-v2-plan/types';
import { uouActionAtCheckout } from '@wix/bi-logger-membership/v2';
import { getTermsAndConditionsSettings } from '@wix/pricing-plans-utils/client-data';
import { useBi } from '@wix/yoshi-flow-editor';
import { termsAndConditionsCheckboxAction } from '../utils/bi';

export const useTermsAndConditions = (plan: PublicPlan) => {
  const bi = useBi();
  const { acceptRequired, isAcceptedByDefault } = getTermsAndConditionsSettings(plan.clientData);
  const [isTickedOff, setIsTickedOff] = useState(isAcceptedByDefault);
  const [shouldDisplayError, setShouldDisplayError] = useState(false);
  const showCheckbox = acceptRequired && Boolean(plan.termsAndConditions);
  const isValid = showCheckbox ? isTickedOff : true;

  return useMemo(
    () => ({
      showCheckbox,
      checkbox: {
        isTickedOff,
        setIsTickedOff: (newValue: boolean) => {
          setIsTickedOff(newValue);
          bi.report(uouActionAtCheckout({ action: termsAndConditionsCheckboxAction(newValue), planGuid: plan.id }));
        },
        shouldDisplayError,
        setShouldDisplayError,
        isValid,
      },
      value: plan.termsAndConditions ?? '',
    }),
    [bi, isTickedOff, isValid, plan.id, plan.termsAndConditions, shouldDisplayError, showCheckbox],
  );
};
